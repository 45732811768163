@import "../../../style/App.scss";

.button {
  .button-link {
    background-color: $main-color;
    border: 2px solid $main-color;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 800;
    border-radius: 8px;
  }
  .button-link:hover {
    border: 2px solid $main-color;
    background-color: transparent;
  }
}
