@import "../../style/App.scss";

.team__box {
  max-width: 370px;
  height: 200px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    padding: 24px;
  }

  .team__box-info {
    background-color: $main-color;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    width: 80%;
    padding: 5px 20px;

    p {
      color: $black-color;
    }
    p:nth-child(2) {
      color: $white-color;
    }
  }
}

.team__box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
