@import "../../../style/App.scss";

.portfolio__box {
  position: relative;
  // max-width: 370px;
  margin: 15px auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 320px;
    border-radius: 15px;
  }

  .portfolio__hover-info {
    background-color: rgba($main-color, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease;
    border-radius: 15px;

    p {
      text-transform: uppercase;
    }
  }
  .portfolio__hover-info:hover {
    opacity: 1;
    border-radius: 15px;
  }
}

@media screen and (max-width: 767px) {
  .portfolio__box {
    img {
      height: auto;
    }
  }
}
