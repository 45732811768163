@import "../../style/App.scss";

#about {
  padding: 60px 0;
}

.about-box {
  margin: 0 auto;
}

@media screen and (max-width: 990px) {
  #about {
    text-align: center;
  }
}
